import Cookies from 'js-cookie'
import { isEuMember } from 'is-eu-member'
import { isNilOrEmpty, isPresent } from 'utils/helpers'

export const COUNTRY_CODE_COOKIE = 'country'
export const GDPR_ACCEPTANCE_COOKIE = 'moove_gdpr_popup'

export const isCookiesRestricted = (countryCode) => {
  try {
    const code = countryCode || Cookies.get(COUNTRY_CODE_COOKIE)

    const isEuropeUnion = isPresent(code) ? isEuMember(code) : true

    if (!isEuropeUnion) return false
    const cookiesAccepted = Cookies.get(GDPR_ACCEPTANCE_COOKIE)

    return isNilOrEmpty(cookiesAccepted)
  } catch (e) {
    return true
  }
}
