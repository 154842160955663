import Cookies from 'js-cookie'
import * as R from 'ramda'
import queryString from 'query-string'
import { isNilOrEmpty, isPresent, isStaging } from 'utils/helpers'

export const COOKIE_NAME = {
  FIRST_CLICK: 'wpstatsrtlyfc',
  LAST_CLICK: 'wpstatsrtlylc',
  VISITS_COUNT: 'wpstatsrtlystat',
  SESSION_INFO: 'wpstatsrtlysession'
}

export const EXPIRES_IN = 180 // days

export const getISOTimestamp = () => new Date().toISOString()

export const getCookieDomain = () => {
  const hostname = window?.location?.hostname || ''

  const firstDotIndex = R.indexOf('.', hostname)
  if (firstDotIndex === -1) return hostname

  return R.slice(firstDotIndex, Infinity, hostname)
}

export const getUTMParams = () => {
  const parsedQuery = queryString.parse(window?.location?.search)

  return R.pick(
    ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'],
    parsedQuery
  )
}

export const collectClickCookieData = () => {
  const time = getISOTimestamp()
  const ref = window?.document?.referrer
  const utm = getUTMParams()

  if (isNilOrEmpty(utm)) return { time, ref }

  return { time, ref, utm }
}

const getSessionInfo = () => {
  const cookie = Cookies.get(COOKIE_NAME.SESSION_INFO)

  if (isNilOrEmpty(cookie)) return {}

  return JSON.parse(cookie)
}

const setSessionInfo = (data) => {
  const in30Minutes = new Date(new Date().getTime() + 30 * 60 * 1000)

  return Cookies.set(
    COOKIE_NAME.SESSION_INFO,
    JSON.stringify(data),
    {
      secure: true,
      domain: getCookieDomain(),
      path: '/',
      expires: in30Minutes
    }
  )
}

const putSessionInfo = (updates) => {
  const cookie = getSessionInfo()
  const data = R.mergeRight(cookie, updates)

  return setSessionInfo(data)
}

const setClickCookie = (name, expires) => {
  const data = collectClickCookieData()

  if (isStaging) console.log(`SET COOKIE:: ${name}`, data)

  return Cookies.set(
    name,
    JSON.stringify(data),
    {
      secure: true,
      domain: getCookieDomain(),
      path: '/',
      expires
    }
  )
}

export const captureInitialClick = (expires = EXPIRES_IN) => {
  const cookie = Cookies.get(COOKIE_NAME.FIRST_CLICK)
  if (isNilOrEmpty(cookie)) return setClickCookie(COOKIE_NAME.FIRST_CLICK, expires)

  if (isStaging) console.log(`SET COOKIE:: ${COOKIE_NAME.FIRST_CLICK}`, 'Cookie already exist')

  return cookie
}

export const captureLastClick = (expires = EXPIRES_IN) => {
  const { isLastClickCaptured } = getSessionInfo()

  if (isLastClickCaptured) {
    if (isStaging) console.log(`SET COOKIE:: ${COOKIE_NAME.LAST_CLICK}`, 'Current session already captured')

    return null
  }

  putSessionInfo({ isLastClickCaptured: true })

  return setClickCookie(COOKIE_NAME.LAST_CLICK, expires)
}

export const captureVisit = () => {
  const { isVisitCaptured } = getSessionInfo()

  if (isVisitCaptured) {
    if (isStaging) console.log(`SET COOKIE:: ${COOKIE_NAME.VISITS_COUNT}`, 'Current session already captured')

    return null
  }

  const cookie = Cookies.get(COOKIE_NAME.VISITS_COUNT)
  const visitsCount = isPresent(cookie) ? Number(cookie) : 0

  const count = R.inc(visitsCount)

  if (isStaging) console.log(`SET COOKIE:: ${COOKIE_NAME.VISITS_COUNT}`, count)

  putSessionInfo({ isVisitCaptured: true })

  return Cookies.set(
    COOKIE_NAME.VISITS_COUNT,
    count,
    {
      secure: true,
      expires: EXPIRES_IN,
      domain: getCookieDomain(),
      path: '/'
    }
  )
}

export const trackAttribution = () => {
  const sessionInfo = getSessionInfo()
  if (isPresent(sessionInfo)) {
    if (isStaging) console.log(`SET COOKIE:: ${COOKIE_NAME.SESSION_INFO}`, 'Session info cookie expiry date updated')

    setSessionInfo(sessionInfo)
  }

  captureInitialClick()
  captureLastClick()
  captureVisit()
}
