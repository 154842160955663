import { trackAttribution } from 'attribution-cookie'
import { isCookiesRestricted } from 'country-detect'

document.addEventListener('DOMContentLoaded', () => {
  if (isCookiesRestricted()) {
    document.querySelector('.moove-gdpr-infobar-allow-all')
      .addEventListener('click', trackAttribution)
  } else {
    trackAttribution()
  }
})
